body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171719;
  color: #f6f6f6;
  font-size: 40px;
  padding: 20px;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 30px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-size: 12px;
  font-weight: bold;
}

.referral-link {
  color: #f6f6f6;
  text-decoration: none;
  font-size: 30px;
  font-weight: bold;
}

.product-card{
	max-width: 600px;
	margin: 0 auto;
	background-size: contain;
	height: 300px;
	text-decoration: none !important;
	padding-top: 20px;
	font-weight: bold;
	margin-bottom: 5px;
}

.model-y{
	background: url('https://digitalassets-shop.tesla.com//image/upload/c_scale,w_640/f_auto/q_auto/v1/stage/lootbox/modely.png') no-repeat center center;
}
.model-3{
	background: url('https://digitalassets-shop.tesla.com//image/upload/c_scale,w_640/f_auto/q_auto/v1/content/dam/tesla/lootbox/lootbox_landing_page/modelnv35.png') no-repeat center center;
}
.model-x{
	background: url('https://digitalassets-shop.tesla.com//image/upload/c_scale,w_640/f_auto/q_auto/v1/content/dam/tesla/lootbox/lootbox_landing_page/model_x.png') no-repeat center center;
}
.model-s{
	background: url('https://digitalassets-shop.tesla.com//image/upload/c_scale,w_640/f_auto/q_auto/v1/content/dam/tesla/lootbox/lootbox_landing_page/model_s.png') no-repeat center center;
}

.hidden{
	background: url('../public/logo.png') no-repeat center center;
	background-size: contain;
}



.order-now{
	background-color: #4c4c4c;
	color: #ffffff;
	padding: 10px;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	text-decoration: none;
	display: block;
	width: 200px;
	margin: 0 auto;
	border-radius: 5px;border-radius: 5px;
	margin-top: 200px
}

.disclaimer{
	font-size: 10px;
	text-align: center;
	margin-top: 20px;
	max-width: 600px;
	margin: 0 auto;
	color: #585858;
	margin-top: 30px;
}

.action-text{
	font-size: 13px;
	text-align: center;
	margin-top: 20px;
	max-width: 600px;
	margin: 0 auto;
	margin-top: 30px;
	line-height: 17px;
}

.blue{
	background-color: #3e6ae1;
	color: #fff;
}

.group-title{
	font-size: 25px;
	font-weight: bold;
	margin-top: 50px;
	margin-bottom: 0px;
}

.product-suggestion-card{
	max-width: 600px;
	margin: 0 auto;
	background-size: contain;
	height: 200px;
	text-decoration: none !important;
	padding-top: 20px;
	font-weight: bold;
	margin-bottom: 5px;
}

.product-suggestion-card.charger{
	background: url('../public/wall-charger.png') no-repeat center center;
}

.product-suggestion-card.threedmat{
	background: url('../public/3dmats.png') no-repeat center center;
}

.product-suggestion-card.screen{
	background: url('../public/screen.png') no-repeat center center;
}

.product-grid .product{
	font-size: 20px;
}

.third-party-link{

	padding: 10px;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	text-decoration: none;
	display: block;
	width: 200px;
	margin: 0 auto;
	border-radius: 5px;border-radius: 5px;
	margin-top: 10px
}

.body-text{
	font-size: 14px;
	margin-top: 20px;
	max-width: 600px;
	margin: 0 auto;
	margin-top: 30px;
	text-align: left;
	font-weight: normal;
}